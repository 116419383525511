import React, { useCallback } from "react";
import { withRouter } from "react-router";
import app from "./base";
const logo = require("../assets/logo.png");

const SignUp = ({ history }) => {
  const handleSignUp = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  return (
    <div>
      <img
        src={logo}
        style={{
          width: 500,
          height: 100,
          marginTop: 20,
          marginBottom: 20,
          alignSelf: "center"
        }}
      />
      <form onSubmit={handleSignUp} style={{ flexDirection: "column" }}>
        <label style={{ marginRight: 75, marginTop: 50, fontSize: 30 }}>
          Email
          <input
            class="input-group input-group-lg input-group-text"
            name="email"
            type="email"
            placeholder="Email"
            style={{ marginRight: 50 }}
          />
        </label>

        <label style={{ fontSize: 30, marginRight: 20 }}>
          Password
          <input
            class="input-group input-group-lg input-group-text"
            name="password"
            type="password"
            placeholder="Password"
            style={{ marginRight: 50 }}
          />
        </label>
        <button
          class="btn btn-primary btn-lg"
          type="submit"
          style={{ backgroundColor: "#f56416", borderWidth: 0 }}
        >
          Get Started
        </button>
      </form>
    </div>
  );
};

export default withRouter(SignUp);

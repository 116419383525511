import * as firebase from "firebase/app";
import "firebase/auth";
import firestore from "firebase/firestore";

const settings = { timestampsInSnapshots: true };

const app = firebase.initializeApp({
  apiKey: "AIzaSyATNHS_i1cZm0SfiD4z7MrD9Ck_AReyNYU",
  authDomain: "appmarket-bc85e.firebaseapp.com",
  databaseURL: "https://appmarket-bc85e.firebaseio.com",
  projectId: "appmarket-bc85e",
  storageBucket: "appmarket-bc85e.appspot.com",
  messagingSenderId: "24040915513",
  appId: "1:24040915513:web:72a29eb0421c289a7a83e8",
  measurementId: "G-2K48BP7KSN"
});

firebase.firestore().settings(settings);
export { firebase };
export default app;


import React from "react";
import axios from "axios";
import "./App.css";
import Search from "./components/search";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "../src/components/Auth";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./components/Home"
import Login from "./components/Login";
import SignUp from "./components/SignUp";

// import SignIn from './components/signInFirst';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <PrivateRoute exact path="/" component={Search} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
// function App() {
//   return (
//     <div className="App">
//       <Search />
//     </div>
//   );
// }

// export default App;

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Table from "react-bootstrap/Table";
import axios from "axios";
import Row from "./tableRow";
import app from "./base";
import { firebase } from "./base";

const logo = require("../assets/logo.png");

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection("/searches");

    this.state = {
      loading: true,
      data: [],
      value: "",
      topTopic: "",
      hightLow: 0
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.sortHighLow = this.sortHighLow.bind(this);
  }
  handleInput(event) {
    this.setState({ value: event.target.value });
  }
  getTopTopics(data) {
    let allTopics = [];
    let counter = {};
    let length = data.length;
    for (let i = 0; i < length; i++) {
      if (data[i].topic !== undefined) {
        let topic = data[i].topic;
        if (topic === undefined) {
          allTopics.push(topic);
        } else {
          let inSide = allTopics.indexOf(topic) > -1;
          if (inSide === false) {
            allTopics.push(topic);
            counter[topic] = 1;
          }
          if (inSide === true) {
            counter[topic] = counter[topic] + 1;
          }
        }
      }
    }
    let first = Object.keys(counter).reduce((a, b) =>
      counter[a] > counter[b] ? a : b
    );
    this.setState({ topTopic: first });
  }
  sortHighLow(data) {
    //   let data = this.state.data
    console.log(data);
  }

  handleClick() {
    axios
      .get(
        `https://graph.facebook.com/search?type=adinterest&q=[${this.state.value}]&limit=10000&locale=en_US&access_token=EAAFPAsTPWQMBAErDDCPQCisBWNtn9qukv447dBUo3R1zewxwGNILaBQKDVe5Wq15bZCeUZBers9rbxMGxcJD1oP3292kwNX93EJTO8gppZCpi0T8JAR6Vt6FmbDIG2ZA7Hh0TxiUOQoEMgCGZCmDHGagYDCXXgC1LZBovKoQezXOIu6q6NHDELCHmqKZCWrOIQZD`
      )
      .then(response => {
        let x = response.data.data;
        console.log(x);
        this.setState({ data: response.data.data });
        this.setState({ loading: false });
        this.getTopTopics(this.state.data);
        this.setState({ data: this.state.data });
        let searchValue = this.state.value;
        this.ref
          .add({
            value: searchValue,
            queryResult: this.state.data
          })
          .then(() => {
            console.log("");
          })
          .catch(error => {
            console.log(error);
          });
      });
  }
  componentDidMount() {
    this.sortHighLow(this.state.data);
  }

  render() {
    if (this.state.loading === false) {
      return (
        <div>
          <img
            src={logo}
            style={{
              width: 500,
              height: 100,
              marginTop: 20,
              marginBottom: 20,
              alignSelf: "center"
            }}
          />
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => app.auth().signOut()}
            style={{ backgroundColor: "#f56416", borderWidth: 0 }}
          >
            Sign out
          </button>
          <div
            class="input-group mb-3"
            style={{ paddingLeft: 150, paddingRight: 150 }}
          >
            <input
              type="text"
              class="form-control"
              value={this.state.value}
              onChange={this.handleInput}
              placeholder="Search For Interest"
            />
            <div class="input-group-prepend">
              <button
                class="btn btn-primary"
                style={{ backgroundColor: "#f56416", borderWidth: 0 }}
                type="button"
                id="button-addon1"
                onClick={this.handleClick}
              >
                Search
              </button>
            </div>
          </div>
          <h2>Most Common Topic:</h2>
          <h3>{this.state.topTopic}</h3>
          <table class="table table-hover">
            <thead style={{ backgroundColor: "#242f40", color: "white" }}>
              <tr>
                <th>Interest Name</th>
                <th onClick={this.sortHighLow}>Size</th>
                <th>Category</th>
                <th>Subcategory</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map(datas => (
                <Row
                  name={datas.name}
                  topic={datas.topic}
                  size={datas.audience_size}
                  otherInter={datas.disambiguation_category}
                />
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div>
        <img
          src={logo}
          style={{ width: 500, height: 100, marginTop: 20, marginBottom: 20 }}
        />
        <button
          type="button"
          class="btn btn-primary"
          onClick={() => app.auth().signOut()}
          style={{ backgroundColor: "#f56416", borderWidth: 0 }}
        >
          Sign out
        </button>
        <div
          class="input-group mb-3"
          style={{ paddingLeft: 150, paddingRight: 150 }}
        >
          <input
            type="text"
            class="form-control"
            value={this.state.value}
            onChange={this.handleInput}
            placeholder="Search For Interest"
          />
          <div class="input-group-prepend">
            <button
              class="btn btn-primary"
              style={{ backgroundColor: "#f56416", borderWidth: 0 }}
              type="button"
              id="button-addon1"
              onClick={this.handleClick}
            >
              Search
            </button>
          </div>
        </div>
        <table class="table table-hover">
          <thead style={{ backgroundColor: "#242f40", color: "white" }}>
            <tr>
              <th>Interest Name</th>
              <th onClick={this.sortHighLow}>Size</th>
              <th>Category</th>
              <th>Subcategory</th>
            </tr>
          </thead>
        </table>
      </div>
    );
  }
}

import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from "./base.js";
import { AuthContext } from "./Auth.js";
const logo = require("../assets/logo.png");

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div style={{ flexDirection: "column", flex: 2 }}>
      <img src={logo} style={{width:400, height:100}}/>
      <form onSubmit={handleLogin}>
        <label style={{marginRight:75, marginTop:50, fontSize:30}}>
          Email
          <input
            class="input-group input-group-lg input-group-text"
            name="email"
            type="email"
            placeholder="Email"
            style={{marginRight:50}}
          />
        </label>

        <label style={{fontSize:30, marginRight:20}}>
          Password
          <input
            class="input-group input-group-lg input-group-text"
            name="password"
            type="password"
            placeholder="Password"
            style={{marginRight:50}}
          />
        </label>
        <button class="btn btn-primary btn-lg" type="submit" style={{backgroundColor: "#f56416", borderWidth:0}}>
          Log in
        </button>
      </form>
    </div>
  );
};

export default withRouter(Login);

import React, { Component, Table } from "react";
import axios from "axios";
export default class Row extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <tr>
        <td>{this.props.name}</td>
        <td>{this.props.size}</td>
        <td>{this.props.topic}</td>
        <td>{this.props.otherInter}</td>
      </tr>
    );
  }
}
